import React from "react"

export default props => (
    <div className="pg-head margin-top-0">
        <img class="pg-head--image" src={ props.bgImage } alt="" width="1300" height="520"></img>
        <div className="container">
            <div className="title-wrap">
                <h1
                className="has-text-weight-bold is-size-3-mobile is-size-2-tablet is-size-1-desktop is-family-secondary"
                >
                { props.titleText }
                </h1>
                <span className="is-uppercase is-size-6-mobile is-size-4-tablet is-size-4-desktop" style={{ letterSpacing: '2px', display: props.subHeading ? 'block' : 'none' }}>{ props.subHeading ? props.subHeading : '' }</span>
            </div>
        </div>
    </div>
)