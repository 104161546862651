import React from 'react'
import PropTypes from 'prop-types'
import { Link, graphql } from 'gatsby'
import Helmet from 'react-helmet'
import Layout from '../components/Layout'
import PageHeader from '../components/PageHeader'
import headerImage from '../img/bg-edu.jpg'
import Content, { HTMLContent } from '../components/Content'

export const ResourcePageTemplate = ({ title, intro, content, contentComponent }) => {
    const PageContent = contentComponent || Content

    return (
      <div className="page-wrap resource-page">
        <Helmet titleTemplate="%s | Mast Cell Hope">
          <title>{ title }</title>
        </Helmet>
        <PageHeader bgImage={ headerImage } titleText="Learning Resources" />
        <section className="section has-background-primary has-text-white">
          <div className="container">
            <div className="columns">
              <div className="column is-10 is-offset-1">
                <div className="content">
                  <h2 className="title has-text-centered has-text-white is-uppercase is-size-3 is-size-5-mobile">{intro.heading}</h2>
                  <span className="is-size-6-mobile is-size-5">{intro.text}</span>
                </div>
              </div>
            </div>
          </div>
        </section>
        <div className="container">
          <div className="section">
            <PageContent className="content" content={content} />
          </div>
        </div>
      </div>
    )
}

ResourcePageTemplate.propTypes = {
    title: PropTypes.string,
    intro: PropTypes.shape({
      heading: PropTypes.string,
      text: PropTypes.string,
    }),
    content: PropTypes.string,
    contentComponent: PropTypes.func,
}

const ResourcePage = ({ data }) => {
    const { markdownRemark: post } = data

    return (
        <Layout>
            <ResourcePageTemplate
                contentComponent={HTMLContent}
                title={post.frontmatter.title}
                intro={post.frontmatter.intro}
                content={post.html}
            />
        </Layout>
    )
}

ResourcePage.propTypes = {
    data: PropTypes.shape({
        markdownRemark: PropTypes.shape({
            frontmatter: PropTypes.object,
        }),
    }),
}

export default ResourcePage

export const ResourcePageQuery = graphql`
query ResourcePage($id: String!) {
  markdownRemark(id: { eq: $id }) {
    html
    frontmatter {
      title
      intro {
        heading
        text
      }
    }
  }
}
`